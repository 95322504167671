import React, { useEffect, useState } from 'react';
import HeadMaker from '../HeadMaker';
import { getAllFAQs, getProgramGroups, getStats } from '../api/strapi';
import Layout from '../shared/Layout/Layout';
import {
  PrecourseIntro,
  PrecourseAbout,
  PrecourseExpectations,
  PrecourseFAQ,
  PrecoursePrice,
  PrecourseWhatYouGet,
  TargetAudience,
  Questions,
  GraduatesContent,
  PrecourseBenefits,
  PreCourseDetails,
  PrecourseDsProgram,
  PrecoursePathToDs,
  ReviewsWrapper,
} from '../widgets';

import { useAboutQuery } from '../app/queries/about.query';
import { useEcbWorksQuery } from '../app/queries/howecbworks.query';
import { usePaymentQuery } from '../app/queries/payment.query';
import { useProgramQuery } from '../app/queries/program.query';
import { usePnRQuery } from '../app/queries/projectsAndReviews.query';
import { useStudentsQuery } from '../app/queries/students.query';
import { useStudentsWorkQuery } from '../app/queries/studentsWork.query';
import { useTargetQuery } from '../app/queries/target.query';
import data from '../pages-fsd/DatascienceBasicsPage/model/datascience.data';

export function Head(props) {
  return <HeadMaker {...props} seo={data.seo} />;
}
const mainData = {
  leadPage: 'DS Online_Подготовительная программа',
  program: 'DS Prepare',
  tags: ['подготовка'],
};

const leadData = {
  program: {
    ...mainData,
    eventName: 'Программа обучения Data Science с нуля',
    leadAction: 'Заявка на программу',
    leadBlock: 'Как поступить',
  },
  questions: {
    ...mainData,
    eventName: 'Оставьте ваши контакты для связи',
    leadAction: 'Получить консультацию',
    leadBlock: 'Остались вопросы',
  },
};

function DsIntense() {
  const { ecbWorksImgs } = useEcbWorksQuery();
  const { targetImgs } = useTargetQuery();
  const { programImgs } = useProgramQuery();
  const { paymentImgs } = usePaymentQuery();
  const { aboutImgs } = useAboutQuery();
  const { reviewsImgs } = usePnRQuery();
  const { studentsData } = useStudentsQuery();
  const { studentsWorkData } = useStudentsWorkQuery();
  const [groups, setGroups] = useState([]);
  const [info, setInfo] = useState(data.about.statistic);
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    const ac = new AbortController();
    getProgramGroups(ac, null, null, true).then(
      (groups) =>
        groups &&
        setGroups(groups.filter((group) => group.courseType === 'PREPARE_DS')),
    );
    getStats().then((stats) => {
      setInfo((prev) =>
        prev.map((item) => {
          const fetchedItem = stats.find((el) => el.id === item.id);
          return {
            ...item,
            value: fetchedItem?.statTitle,
            description: fetchedItem?.statSubTitle,
          };
        }),
      );
    });
    const getData = async () => {
      try {
        const questions = await getAllFAQs(ac);
        setQuestions(questions[2].FAQ_dynamiczone);
      } catch (e) {
        console.log(e);
      }
    };
    getData();
    return () => {
      ac.abort();
    };
  }, []);

  return (
    <Layout leadPage="DS intense">
      <PrecourseIntro {...data.intro} theme="medium" />
      <PrecourseBenefits benefits={data.benefits} />
      <TargetAudience {...data.target} imgs={targetImgs} />
      <PreCourseDetails {...data.details} />
      <PrecourseWhatYouGet {...data.whatYouGet} images={ecbWorksImgs} />
      <PrecourseDsProgram
        {...data.program}
        images={programImgs}
        leadData={leadData.program}
      />
      <PrecourseExpectations
        {...data.courseExpectations}
        images={ecbWorksImgs}
      />
      <PrecoursePrice {...data.price} images={paymentImgs} groups={groups} />
      <PrecoursePathToDs
        {...data.pathToIt}
        images={ecbWorksImgs}
        leadData={leadData.program}
      />
      <PrecourseAbout {...data.about} statistic={info} images={aboutImgs} />
      <ReviewsWrapper {...data.reviews} images={reviewsImgs}>
        <GraduatesContent
          {...data.graduates}
          data={{
            people: data.graduates.data.people,
          }}
          studentsData={studentsData}
          studentsWorkData={studentsWorkData}
          noBg
        />
      </ReviewsWrapper>
      <PrecourseFAQ questions={questions} {...data.faq} />
      <Questions {...data.footer} extraLeadData={leadData.questions} />
    </Layout>
  );
}

export default DsIntense;

import React from 'react';
import { SvgIcon } from '@mui/material';

export function PeopleIcon(props) {
  return (
    <SvgIcon
      sx={{ width: '57px', height: '57px', viewBox: '0 0 57px 57px' }}
      {...props}
      viewBox="0 0 57 57"
    >
      <rect
        x="0.132812"
        y="0.488281"
        width="56"
        height="56"
        rx="20"
        fill="#4520AB"
      />
      <circle
        cx="22.2492"
        cy="25.5285"
        r="7.35073"
        stroke="white"
        strokeWidth="2.20522"
        fill="#4520AB"
      />
      <path
        d="M32.5416 38.76C31.2867 37.3618 29.7969 36.2526 28.1573 35.4958C26.5177 34.7391 24.7603 34.3496 22.9856 34.3496C21.2109 34.3496 19.4536 34.7391 17.814 35.4958C16.1744 36.2526 14.6846 37.3618 13.4297 38.76"
        stroke="white"
        strokeWidth="2.20522"
        fill="#4520AB"
      />
      <path
        d="M33.278 32.8792C37.7437 32.8792 41.3638 29.5881 41.3638 25.5284C41.3638 21.4688 37.0086 18.1777 32.543 18.1777"
        stroke="white"
        strokeWidth="2.20522"
        fill="#4520AB"
      />
      <path
        d="M42.8339 38.7593C41.58 37.0287 40.0913 35.656 38.4529 34.7194C36.8145 33.7828 35.0585 33.3008 33.2852 33.3008"
        stroke="white"
        strokeWidth="2.20522"
        fill="#4520AB"
      />
    </SvgIcon>
  );
}

import { PeopleIcon } from '../../../kit/icons/PeopleIcon';
import { RocketIcon } from '../../../kit/icons/RocketIcon';
import CalendarIcon from '../../../kit/icons/CalendarIcon';
import ClockIcon from '../../../kit/icons/ClockIcon';
import DSlargeIcon from '../../../kit/icons/DSlargeIcon';
import StonksIcon from '../../../kit/icons/StonksIcon';

const data = {
  seo: {
    title:
      'Data Scientist обучение с нуля | Курс Python Junior для анализа данных онлайн',
    meta: [
      {
        property: null,
        name: 'description',
        content:
          'Курсы Python для Data Science для начинающих онлайн. Обучение на Junior Data Scientist с нуля с трудоустройством. План обучения курса анализа данных на сайте школы Эльбрус Буткемп.',
      },
      { property: 'og:type', name: null, content: 'product' },
      {
        property: 'og:title',
        name: null,
        content:
          'Data Scientist обучение с нуля | Курс Python Junior для анализа данных онлайн',
      },
      {
        property: 'og:description',
        name: null,
        content:
          'Курсы Python для Data Science для начинающих онлайн. Обучение на Junior Data Scientist с нуля с трудоустройством. План обучения курса анализа данных на сайте школы Эльбрус Буткемп.',
      },
      {
        property: 'og:image',
        name: null,
        content:
          'https://elbrusboot.camp/static/developer-9f1b6e5e395c2f3f8b555781f0d17be1.png',
      },
      {
        property: 'og:site_name',
        name: null,
        content: 'Эльбрус Буткемп',
      },
      { property: 'og:locale', name: null, content: 'ru_RU' },
      { property: 'fb:app_id', name: null, content: '581051622277135' },
      { property: 'twitter:card', name: null, content: 'summary_large_image' },
      {
        property: 'twitter:site',
        name: null,
        content: 'https://twitter.com/elbrus_bootcamp',
      },
      {
        property: 'twitter:image:src',
        name: null,
        content:
          'https://elbrusboot.camp/static/developer-9f1b6e5e395c2f3f8b555781f0d17be1.png',
      },
    ],
  },
  intro: {
    title: 'Основы python    (в data science)',
    description:
      'Освойте с нуля язык программирования Python и подготовьте уверенную базу для обучения на курсе Data Science',
    bg: 'ds-precourse-intro',
    link: '/payment/?leartype=ds.prepare.ru',
    metrics: {
      ym: 'PreDSTopBuy',
      mt: '',
    },
  },
  benefits: [
    {
      type: '10',
      text: 'живых практических занятий: типы данных, функции, циклы и методы в Python',
    },
    {
      type: '14',
      text: 'дней погружения для комфортного старта на курсе Data Science',
    },
    {
      type: '20',
      text: 'эффективных занятий в сопровождении ментора ',
    },
    {
      type: '2',
      text: 'месяца доступа к материалам курса',
    },
  ],
  target: {
    title: 'Кому подойдет курс',
    list: [
      {
        title: 'Тем, кто хочет изучать Python с 0',
        explaining:
          'Вы хотите освоить с нуля востребованный язык программирования, который используют разные IT-специалисты.',
        image: 'questions',
        margin: { xs: '-10px', md: '-25px' },
      },
      {
        title: 'Тем, кто хочет начать путь в Data Science',
        explaining:
          'Вы мечтаете сменить профессию на Data Scientist и работать с данными.',
        image: 'lectionsDs',
        margin: '-1px',
      },
      {
        title: 'Тем, кто хочет подготовиться к вступительному интервью',
        mobileTitle: 'Тем, кто хочет подготовиться к интервью',
        explaining:
          'Вы нацелены поступать в буткемп и хотите быть уверены в своих знаниях.',
        image: 'interview',
        margin: '-11px',
      },
    ],
  },
  details: {
    title: 'Детали курса',
    subtitle: '',
    details: [
      {
        title: 'График',
        info: '2 недели по будням ',
        Icon: CalendarIcon,
      },
      {
        title: 'Длительность занятия',
        info: '90 минут по вечерам ',
        Icon: ClockIcon,
        format: 'онлайн',
      },
    ],
  },
  whatYouGet: {
    title: 'Что вас ждет?',
    details: [
      'Живые занятия в группе и первые знакомства в IT-комьюнити',
      'Освоение базового синтаксиса языка Python с нуля',
      'Поддержка ментора по любым вопросам и обратная связь по итогам занятий',
    ],
    image: 'whatYouGetDs',
    durationImg: '15hoursDs',
  },
  program: {
    title: ['Программа погружения', 'в основы Python'],
    list: [
      {
        day: 'Неделя 1',
        points: [
          'Google colab, jupyter notebook',
          'Типы данных и условные конструкции',
          'Операции с числами и математические функции',
          'Циклы',
        ],
        resultTitle: 'Результат первой недели',
        resultPoints: [
          'Знакомство с менторами буткемпа и их методами преподавания ',
          'Теоретическая база знаний для дальнейшего изучения более сложных тем Python',
        ],
        description: null,
        image: null,
      },
      {
        day: 'Неделя 2',
        points: [
          'Работа со строками',
          'Методы списков',
          'Методы словарей',
          'Создание функций',
          'Введение в объектно-ориентированное программирование: классы в Python',
        ],
        resultTitle: 'Результат второй недели',
        resultPoints: [
          'Погружение в Python и практические навыки решения задач',
          'Тест-драйв формата буткемп и подготовка к вступительному интервью для поступления',
        ],
        description: null,
        image: null,
      },
      // {
      //   day: 'Среда',
      //   points: [
      //     'Условные ветвления, тернарный оператор',
      //     'Копирование и сравнение по ссылке',
      //     'Массивы: PUSH, POP, Shift, Unshift, обращение через [ ]',
      //   ],
      //   description: null,
      //   image: null,
      // },
      // {
      //   day: 'Четверг',
      //   points: [
      //     'Циклы: while, for..let, for..of, do..while',
      //     'Методы массивов slise и splice. Includes, indexof, find, reverse',
      //     'Методы и операции со строками: split (и join), tolowercase, touppercase, slise. Сложение и template literal.',
      //   ],
      //   description: null,
      //   image: null,
      // },
      // {
      //   day: 'Пятница',
      //   points: [
      //     'Функции. Суть, способы объявления',
      //     'Области видимости',
      //     'Методы массивов: map, foreach, filter, sort, reduse',
      //   ],
      //   description: null,
      //   image: null,
      // },
      // {
      //   day: 'Неделя практики',
      //   points: null,
      //   description:
      //     'Вы будете решать задачи — от простых к более сложным, выполнять домашние задания и разбирать их вместе с ментором.',
      //   image: 'precourse-practice',
      // },
    ],
    buttonText: 'получить материалы',
    explication: {
      image: 'python',
      text: 'Перед курсом необходимо знать базовый синтаксис python. Вышлем бесплатные материалы для подготовки.',
    },
    metrics: {
      ym: 'PreDSProgram',
    },
  },
  courseExpectations: {
    title: 'Что даст вам курс',
    image: 'expectations-ds',
    tallCard: true,
    details: [
      {
        title: 'Знания Python с самого нуля',
        description:
          'Познакомитесь с основами одного из самых популярных языков программирования, даже если ничего не знали о нём ранее.',
        Icon: DSlargeIcon,
        isVisible: true,
      },
      {
        title: 'Представление о работе Data Scientist',
        description:
          'Узнаете особенности программирования в области Data Science и поймете, чем занимаются специалисты этой профессии.',
        Icon: StonksIcon,
        isVisible: true,
      },
      {
        title: '',
        description: '',
        Icon: ClockIcon,
        isVisible: false,
      },
      {
        title: '',
        description: '',
        Icon: ClockIcon,
        isVisible: false,
      },
      {
        title: 'Полезные контакты',
        description:
          'Заведете первые знакомства в IT-сфере и получите доступ в закрытые чаты, где можно обмениваться опытом и задавать вопросы.',
        Icon: PeopleIcon,
        isVisible: true,
      },
      {
        title: 'Готовность к поступлению в Эльбрус Буткемп',
        description:
          'Пройдете все темы, необходимые для успешного прохождения технического интервью.',
        Icon: RocketIcon,
        isVisible: true,
      },
    ],
  },
  price: {
    title: 'Стоимость обучения',
    link: '/payment/?leartype=ds.prepare.ru',
    points: [
      'Фундамент для старта в IT',
      'Живые лекции и структурированные знания',
      'Много практики и групповые разборы домашних заданий с ментором',
    ],
    bonuses: [
      {
        point: '2 месяца доступа к материалам курса',
        image: 'video-lections',
      },
      {
        point: '>15 часов практики',
        image: 'practice-time',
      },
      {
        point: 'Курс можно пройти бесплатно*',
        image: 'discount',
      },
    ],
    metrics: {
      ym: 'PreDSPriseBuy',
    },
  },
  about: {
    title: 'О школе Эльбрус Буткемп',
    statistic: [
      {
        id: 6,
        value: '--',
        description: 'студентов успешно...',
        link: null,
      },
      {
        id: 1,
        value: '--',
        description: 'студентов выпущено...',
        link: null,
      },
      {
        id: 2,
        value: '--',
        description: 'студентов находят работу..',
        link: {
          title: 'исследование hh.ru',
          url: '/job-report2023/',
        },
      },
    ],
    description:
      'Наши карьерные коучи помогают с резюме, консультируют по IT‑рынку, делятся лайфхаками по трудоустройству и остаются на связи в течение года после выпуска.',
  },
  pathToIt: {
    title: 'Ваш путь в IT',
    details: [
      {
        text: 'Задумались попробовать себя в Data Science и получили',
        linkName: 'бесплатные материалы для подготовки',
        link: 'https://www.figma.com/file/4G5o4WZ9OworkJTuyT6xzf/ECB-NEWGRID?type=design&node-id=13859-27143&mode=design&t=hXnRhxVVtm3xNyYw-0',
      },
      {
        text: '«Основы JavaScript»',
        linkName: 'Запишитесь на курс',
        link: 'https://www.figma.com/file/4G5o4WZ9OworkJTuyT6xzf/ECB-NEWGRID?type=design&node-id=13859-27143&mode=design&t=hXnRhxVVtm3xNyYw-0',
      },
      {
        text: 'Пройдете вступительное интервью в Эльбрус Буткемп',
      },
      {
        text: 'Освоите востребованную IT-профессию',
      },
      {
        text: 'Получите первый оффер — самое интересное впереди!',
      },
    ],
  },
  faq: {
    title: 'Ответы на частые вопросы',
  },
  reviews: {
    title: 'Отзывы тех, кто прошел курс',
    image: 'reviews/reviewsTitle',
    ymEvent: 'PreDSComments',
  },
  graduates: {
    data: {
      people: [
        {
          photo: 'karpenko',
          fullName: 'Сергей Карпенко',
          content:
            'До курса я знал немного Python и прошел пару курсов статистики на Stepik. Самостоятельная подготовка заняла бы гораздо больше времени — даже на курсе у меня была тема — Machine Learning — на которой я долго не понимал, что происходит:)',
        },
        {
          photo: 'ivanova1',
          fullName: 'Елена Иванова',
          content:
            'Подготовительный курс дал понимание, что меня ждет на основном курсе: много работы, много задач, которые можно решить тысячами способами — и все они верные. А еще именно здесь я впервые познакомилась с нейронками — такими интересными и абсолютно непонятными на тот момент.',
        },
        {
          photo: 'bystrov',
          fullName: 'Илья Быстров',
          content:
            'Курс дает хорошую возможность понять, нравится ли тебе вся эта движуха с Data Science или нет. Ну и плюсом у тебя появляются структурированные знания и команда единомышленников. Лично мне он помог немного структурировать знания и понять, для чего я вообще учил какие-то вещи в Python.',
        },
      ],
    },
  },
  footer: {
    message: 'Готовы ответить на ваши вопросы',
    actions: {
      first: {
        title: 'Оставить заявку',
      },
    },
    ymEvents: {
      consultationFormSubmit: 'PreDSBottomConsultation',
    },
    // myTargetEvents: {
    //   consultationFormSubmit: 'PreDSBottomConsultation',
    // },
  },
};

export default data;

import React from 'react';
import { SvgIcon } from '@mui/material';

export function RocketIcon(props) {
  return (
    <SvgIcon
      sx={{ width: '57px', height: '57px', viewBox: '0 0 57px 57px' }}
      {...props}
      viewBox="0 0 57 57"
      fill="none"
    >
      <rect
        x="0.132812"
        y="0.488281"
        width="56"
        height="56"
        rx="20"
        fill="#4520AB"
      />
      <path
        d="M28.1328 33.2386L24.3828 29.4886M28.1328 33.2386C29.8789 32.5745 31.5539 31.737 33.1328 30.7386M28.1328 33.2386V39.4886C28.1328 39.4886 31.9203 38.8011 33.1328 36.9886C34.4828 34.9636 33.1328 30.7386 33.1328 30.7386M24.3828 29.4886C25.048 27.7629 25.8856 26.1087 26.8828 24.5511C28.3393 22.2223 30.3673 20.3049 32.7741 18.9812C35.1808 17.6575 37.8861 16.9716 40.6328 16.9886C40.6328 20.3886 39.6578 26.3636 33.1328 30.7386M24.3828 29.4886H18.1328C18.1328 29.4886 18.8203 25.7011 20.6328 24.4886C22.6578 23.1386 26.8828 24.4886 26.8828 24.4886M18.7578 35.1136C16.8828 36.6886 16.2578 41.3636 16.2578 41.3636C16.2578 41.3636 20.9328 40.7386 22.5078 38.8636C23.3953 37.8136 23.3828 36.2011 22.3953 35.2261C21.9094 34.7624 21.2694 34.4944 20.5981 34.4736C19.9268 34.4529 19.2714 34.6808 18.7578 35.1136Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#4520AB"
      />
    </SvgIcon>
  );
}
